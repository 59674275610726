/* eslint-disable react/jsx-boolean-value */
import * as React from 'react';
import {
  graphql, HeadFC, useStaticQuery,
} from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FaRegPlayCircle } from 'react-icons/fa';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import Seo from '../components/seo';
import '../index.css';
import Layout from '../components/layout';
import {
  Section, SubTitle, Title, Image, Container, ItemLink, ServiceButton, Wrapper, SectionTitle, MoreButton, PartnersImage, PartnersTitle, H3,
} from '../styles/index.elements';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulMainPage {
        nodes {
          images {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const { images } = data.allContentfulMainPage.nodes[0];
  const services = ['Instalación de puntos de red, voz y datos en categoría 6, 6a y 7a.', 'Instalación de fibra óptica monomodo y multimodo', 'Instalación y mantenimiento de centros de computo', 'Instalación y mantenimiento de Datacenter', 'Acondicionamiento de racks'];
  const services1 = ['Acometidas en media y baja tensión.', 'Sistemas de puesta a tierra.', 'Instalación de redes eléctricas corriente normal y corriente regulada.', 'Diseño y fabricación de tableros de protección eléctricos.', 'Balanceo de cargas.'];

  return (
    <Layout>
      <main>
        <Section>
          <Wrapper>
            <Title>ELECTRIREDES LUSSAN SAS</Title>
            <SubTitle>Empresa de servicios dedicada a la instalacion y mantenimiento de cableado estructurado, redes electricas y seguridad electronica.</SubTitle>
            <Container className="border" width="28%" color="#F8F9FA">
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="medicion de voltaje en breaker" />
              <p>Somos una empresa fundada en Abril 23 de 2003 identificada con NIT 830.120.036-0. Contamos con más de 19 años de experiencia en los campos de Ingeniería de Sistemas y área de telecomunicaciones. Actualmente estamos implementando nuestro Sistema de Gestión de Calidad con el fin de prestar un mejor servicio.</p>
            </Container>
            <Container className="border" width="28%" color="#F8F9FA">
              <Image image={getImage(images[1]) as IGatsbyImageData} alt="rack de comunicaciones" />
              <p>Desarrollamos soluciones en infraestructura de proyectos en diferentes áreas de ingeniería, brindando servicios de instalación y/o mantenimiento en sistemas de <ItemLink to="/servicios/cableado-estructurado">cableado estructurado</ItemLink>, <ItemLink to="/servicios/redes-electricas">redes eléctricas</ItemLink> de media y baja tensión y  <ItemLink to="/servicios/seguridad">sistemas de seguridad electrónica</ItemLink>.</p>
              <ServiceButton to="/servicios">IR A SERVICIOS <FaRegPlayCircle /></ServiceButton>
            </Container>
            <Container className="border" width="28%" color="#F8F9FA">
              <Image image={getImage(images[2]) as IGatsbyImageData} alt="comunicaciones internet" />
              <p>Nuestro personal es altamente calificado y especialista en misiones críticas, conseguimos resultados en corto tiempo creando sistemas escalables y estables, lo que garantiza que la inversión realizada por su compañía en infraestructura tecnológica sea lo más rentable en el menor tiempo posible.</p>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%">
              <SectionTitle>Instalación de cableado estructurado</SectionTitle>
              <p>Estos sistemas deben ser confiables, ágiles, seguros y escalables, es por esto que como <strong>empresa de redes y cableado estructurado</strong> ofrecemos flexibilidad en instalación e independencia de proveedores y protocolos. Contamos con una experiencia y conocimiento de más de  <strong>17 años</strong> en el mercado. Debido a esto  y con ayuda de nuestros aliados estratégicos nos permitimos otorgar <strong>garantías de hasta 25 años sobre nuestros proyectos de cableado estructurado.</strong></p>
              <p>Dentro de los servicios de cableado estructurado que ofrecemos para tu empresa están:</p>
              <ul>
                {
                  services.map((text) => <li key={text}><H3>{text}</H3></li>)
                }
              </ul>
              <MoreButton to="/servicios/cableado-estructurado">Ver mas <BsFillPlusCircleFill /></MoreButton>
            </Container>
            <Container width="45%">
              <Image image={getImage(images[3]) as IGatsbyImageData} alt="Racks centro de computo" max_width="none" padding="true" />
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%">
              <Image image={getImage(images[4]) as IGatsbyImageData} alt="Inspeccion de tablero electrico" max_width="none" padding="true" />
            </Container>
            <Container width="45%">
              <SectionTitle>Instalación de redes eléctricas</SectionTitle>
              <p>Instalamos, diseñamos y hacemos mantenimientos bajo la normatividad y siguiendo los protocolos de seguridad, no solo por garantizar la calidad de la red eléctrica y de la infraestructura tecnológica, sino también para garantizar la seguridad del cliente y de nuestro personal.</p>
              <p>Contamos con <strong>personal calificado</strong> para manejar estas tareas de alto riesgo y para brindarles una solución de acuerdo a la norma <strong>RETIE y NTC.</strong> Dentro de los servicios de redes eléctricas ofrecemos:</p>
              <ul>
                {
                  services1.map((text) => <li key={text}><H3>{text}</H3></li>)
                }
              </ul>
              <MoreButton to="/servicios/redes-electricas">Ver mas <BsFillPlusCircleFill /></MoreButton>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container width="45%">
              <SectionTitle>Instalación de seguridad electrónica ( CCTV y control de acceso )</SectionTitle>
              <p>Por medio de la vigilancia por Internet, usted podrá tener la tranquilidad de poder monitorear las áreas de su interés desde cualquier parte del mundo. La vídeo vigilancia es la evolución de los tradicionales sistemas de CCTV  que se integran con otros sistemas de seguridad para el control de accesos, así usted podrá monitorear, analizar y supervisar los movimientos en su empresa, fábrica o casa.</p>
              <p>Dentro de los servicios de seguridad electrónica ofrecemos:</p>
              <ul>
                {
                  services.map((text) => <li key={text}><H3>{text}</H3></li>)
                }
              </ul>
              <MoreButton to="/servicios/seguridad">Ver mas <BsFillPlusCircleFill /></MoreButton>
            </Container>
            <Container width="45%">
              <Image image={getImage(images[5]) as IGatsbyImageData} alt="Camara de seguridad tipo domo y tipo bala" max_width="none" padding="true" />
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <PartnersTitle>Aliados estrategicos</PartnersTitle>
            <PartnersImage image={getImage(images[6]) as IGatsbyImageData} alt="Aliados estrategicos electriredes" />
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <PartnersTitle>Algunos de nuestros clientes</PartnersTitle>
            <PartnersImage image={getImage(images[7]) as IGatsbyImageData} alt="Algunos clientes electriredes" />
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <Seo
    title="Electriredes lussan ⚡ cableado estructurado, redes electricas y cctv"
    description="Empresa de servicios en instalacion y/o mantenimiento de cableado estructurado, redes electricas y sistemas de seguridad | Bogota, Colombia"
  />
);
