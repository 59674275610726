import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 1.5rem 3rem 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  &:nth-child(even) {
    background-color: #E9ECEF;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1300px;
  margin: auto;
`;

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin-top: 15px;
`;

export const SubTitle = styled.h2`
  text-align: center;
  width: 100%;
  color: #0B71C4;
  font-size: 0.9rem;
  margin-bottom: 25px;
`;

interface Imageprops {
  max_width?: string
  padding?: string

}
export const Image = styled(GatsbyImage)<Imageprops>`
  width: 100%;
  max-width: ${({ max_width }) => (max_width ?? '350px')};
  margin: auto;
  @media screen and (min-width: 788px) {
    padding: ${({ padding }) => (padding ? '0 25px' : 0)};
  }
`;

interface Conrainerprops {
  color?: string
  width?: string
}
export const Container = styled.div<Conrainerprops>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
  background-color: ${({ color }) => (color ?? 'none')};;

  & p {
    padding: 15px 10px;
    text-align: justify;
    font-size: 0.9rem;
    line-height: 1.7em;
  }

  & p strong {
    color: #444444;
  }

  &.border {
    border-top: 5px solid #F4FC54;
    &:nth-child(odd) {
    border-top: 5px solid #013977;
    }
  }

  & ul {
    position: relative;
    width: 100%;
    left: 35px;
    margin-bottom: 15px;

  }

  & li {
    margin-bottom: 7px;
    font-size: 0.9rem;
  }

  @media screen and (min-width: 788px) {
    width: ${({ width }) => (width ?? 'auto')};
  }
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: #35AEF5;
`;

export const ServiceButton = styled(Link)`
  text-decoration: none;
  padding: 5px 10px;
  font-size: 0.9rem;
  color: #2D3E8B;
  &:hover {
    background-color: #EBECED
  }
`;

export const MoreButton = styled(Link)`
  text-decoration: none;
  padding: 10px 10px;
  font-size: 1.2rem;
  color: white;
  display: block;
  background-color: #013977;
  display: flex;
  gap: 7px;
  align-items: center;
  margin-left: 10px;
  &:hover {
    border: 4px solid #013977;
  }
`;

export const SectionTitle = styled.h2`
  padding: 0px 10px 5px;
  border-bottom: 4px solid #587BA4;
  width: 100%;
`;

export const PartnersTitle = styled.p`
  padding: 0px 10px 5px;
  border-bottom: 4px solid #587BA4;
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
`;

export const PartnersImage = styled(GatsbyImage)`
  margin: auto;
  width: 100%;
  margin-bottom: 15px;

  @media screen and (min-width: 788px) {
    width: 80%;
  }
`;

export const H3 = styled.h3`
  font-size: 0.9rem;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: normal;
`;
